import React from "react";
import { Route } from "react-router-dom";
const Token = React.lazy(() => import("./views/Token"));
const Config = React.lazy(() => import("./views/Config"));
const Form = React.lazy(() => import("./views/Form"));
const Review = React.lazy(() => import("./views/Review"));

const Routes = () => (
    <>
        <Route path="/form" component={Form} />
        <Route path="/token" component={Token} />
        <Route path="/config" component={Config} />
        <Route path="/review" component={Review} />
    </>
);

export default Routes;