import React from "react";
import { node } from "prop-types";
import { PageBackground } from "@kallidus/common";
import "./Layout.less";

const Layout = ({ children }) => (
    <PageBackground>
        <div className="page">
            <div className="wrapper">{children}</div>
        </div>
    </PageBackground>
);

Layout.propTypes = {
    children: node,
};

export default Layout;
