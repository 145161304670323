import React from "react";
import { KallidusThemeVariables } from "@kallidus/common";
import Router from "./Router";
import "./App.less";

const App = () => (
    <>
        <KallidusThemeVariables brandPrimaryColor="#005ea5" />
        <Router />
    </>
);

export default App;
