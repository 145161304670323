import React, { Suspense, useState, useEffect, useRef } from "react";
import { HashRouter, useLocation } from "react-router-dom";
import { ErrorHandler, VisuallyHidden } from "@kallidus/common";
import { Helmet } from "react-helmet";
import Layout from "./layouts/Layout";
import Routes from "./Routes";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

// announce new page title
// and reset focus to top of page
const TitleAnnouncer = () => {
    const currentPath = useRef();
    const titleElemRef = useRef();
    const { pathname } = useLocation();
    const [title, setTitle] = useState("");
    const [prevTitle, setPrevTitle] = useState("");
    const onHelmetChange = ({ title: pageTitle }) => {
        setPrevTitle(title);
        setTitle(pageTitle);
    };

    useEffect(() => {
        if (title && title !== prevTitle && titleElemRef.current) {
            // only set focus to it if an internal page change happened;
            // this will intentionally exclude manually history changes using window.history
            const prevPath = currentPath.current;
            currentPath.current = pathname;
            if (prevPath !== currentPath.current) {
                titleElemRef.current.focus();
            }
        }
    }, [titleElemRef, title, prevTitle, pathname]);

    return (
        <>
            <VisuallyHidden component="p" tabIndex="-1" ref={titleElemRef}>
                {title}
            </VisuallyHidden>
            <Helmet onChangeClientState={onHelmetChange} />
        </>
    );
};

const Router = () => (
    <HashRouter basename={baseUrl}>
        <TitleAnnouncer />
        <Layout>
            <ErrorHandler>
                <Suspense fallback="Loading...">
                    <Routes />
                </Suspense>
            </ErrorHandler>
        </Layout>
    </HashRouter>
);

export default Router;
